exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-base-page-template-js-content-file-path-src-content-base-about-us-mdx": () => import("./../../../src/templates/basePageTemplate.js?__contentFilePath=/workspace/src/content/base/about-us.mdx" /* webpackChunkName: "component---src-templates-base-page-template-js-content-file-path-src-content-base-about-us-mdx" */),
  "component---src-templates-base-page-template-js-content-file-path-src-content-base-cookie-policy-mdx": () => import("./../../../src/templates/basePageTemplate.js?__contentFilePath=/workspace/src/content/base/cookie-policy.mdx" /* webpackChunkName: "component---src-templates-base-page-template-js-content-file-path-src-content-base-cookie-policy-mdx" */),
  "component---src-templates-base-page-template-js-content-file-path-src-content-base-privacy-policy-mdx": () => import("./../../../src/templates/basePageTemplate.js?__contentFilePath=/workspace/src/content/base/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-base-page-template-js-content-file-path-src-content-base-privacy-policy-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-content-posts-captain-america-4-moon-knight-link-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/workspace/src/content/posts/captain-america-4-moon-knight-link.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-content-posts-captain-america-4-moon-knight-link-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-content-posts-captain-america-four-pillar-page-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/workspace/src/content/posts/captain-america-four-pillar-page.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-content-posts-captain-america-four-pillar-page-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-content-posts-gentleman-bastards-4-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/workspace/src/content/posts/gentleman-bastards-4.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-content-posts-gentleman-bastards-4-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-content-posts-murderbot-diaries-seriesorder-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/workspace/src/content/posts/murderbot-diaries-seriesorder.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-content-posts-murderbot-diaries-seriesorder-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-content-posts-sidewinder-captain-america-4-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/workspace/src/content/posts/sidewinder-captain-america-4.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-content-posts-sidewinder-captain-america-4-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-content-posts-the-murderbot-diaries-tv-series-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/workspace/src/content/posts/the-murderbot-diaries-tv-series.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-content-posts-the-murderbot-diaries-tv-series-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-content-posts-throne-of-glass-read-order-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/workspace/src/content/posts/throne-of-glass-read-order.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-content-posts-throne-of-glass-read-order-mdx" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/TagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

